/* eslint-disable react/jsx-pascal-case */
import React from 'react';

import Layout from '../components/layout';
import Seo from '../components/seo';
import common from '../messages/common';
import ErrorPage from '../components/ErrorPage';

const FatalErrorPage = () => {
  return (
    <Layout>
      <Seo title={common.seo500Title} />
      <ErrorPage error={500} />
    </Layout>
  );
};

export default FatalErrorPage;
